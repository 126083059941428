import React, { useEffect, useState } from "react";
import Loading from "../components/common/Loading";
import InfiniteScroll from "react-infinite-scroll-component";
import { httpReauest } from "../utils/httpRequest";
import { Shamsi } from "basic-shamsi";
import { CDN_BASE_URL } from "../config";
import CustomModal from "../components/common/Modal";
import { FaTimes, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { priceComma } from "../utils/priceComma";
import { useFormik } from "formik";
import * as Yup from "yup";

const TaskPage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState();
  const [create, setcreate] = useState(false);

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      content: Yup.string().required("required"),
      point: Yup.string().required("required"),
      url: Yup.string().required("required"),
      type: Yup.string().required("required"),
    });
  };

  const formik = useFormik({
    initialValues: {
      content: "",
      point: "",
      url: "",
      type: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      const user = JSON.parse(localStorage.getItem("user"));

      await httpReauest("POST", "/task/admin", values, {
        "x-access-token": user.token,
      }).then(({ data }) => {
        toast.success("successfull");
        formik.resetForm();
        setTimeout(() => {
          setcreate(false);
        }, 1000);
      });
    },
  });

  async function handleDelete(id) {
    const user = JSON.parse(localStorage.getItem("user"));

    await httpReauest(
      "DELETE",
      "/task/" + id,
      {},
      {
        "x-access-token": user.token,
      }
    ).then(({ data }) => {
      toast.success("Task deleted");
      getData();
    });
  }

  useEffect(() => {
    getData();
  }, [create]);

  async function getData() {
    setLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    await httpReauest(
      "GET",
      "/task",
      {},
      { "x-access-token": user.token }
    ).then(({ data }) => {
      setProducts(data.data);
    });
    setLoading(false);
  }

  return (
    <div>
      <div className="w-full">
        {create ? (
          <>
            <div className=" mb-10">
              <h1 className="title">Create Task</h1>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="grid lg:grid-cols-3 gap-3"
            >
              <span className="relative">
                <input
                  id="content"
                  name="content"
                  value={formik.values.content}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full form-brand h-[48px]"
                />
                {formik.errors.content && formik.touched.content && (
                  <small style={{ color: "red", fontSize: "14px" }}>
                    {formik.errors.content}
                  </small>
                )}
                <p className="on-border font-bold">text*</p>
              </span>
              <span className="relative">
                <input
                  id="point"
                  name="point"
                  value={formik.values.point}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
                  className="w-full form-brand h-[48px]"
                />
                {formik.errors.point && formik.touched.point && (
                  <small style={{ color: "red", fontSize: "14px" }}>
                    {formik.errors.point}
                  </small>
                )}
                <p className="on-border font-bold">coin*</p>
              </span>
              <span className="relative">
                <input
                  id="url"
                  name="url"
                  value={formik.values.url}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full form-brand h-[48px]"
                />
                {formik.errors.url && formik.touched.url && (
                  <small style={{ color: "red", fontSize: "14px" }}>
                    {formik.errors.url}
                  </small>
                )}
                <p className="on-border font-bold">url*</p>
              </span>
              <span className="relative">
                <select
                  id="type"
                  name="type"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full form-brand h-[48px]"
                >
                  <option></option>
                  <option>daily</option>
                  <option>partner</option>
                </select>
                {formik.errors.type && formik.touched.type && (
                  <small style={{ color: "red", fontSize: "14px" }}>
                    {formik.errors.type}
                  </small>
                )}
                <p className="on-border font-bold">type*</p>
              </span>
              <span className="col-span-full">
                <button className="btn-brand">submit</button>
              </span>
            </form>
          </>
        ) : (
          <>
            <div className=" mb-10">
              <h1 className="title">Tasks</h1>
            </div>

            {loading ? (
              <Loading />
            ) : (
              <div className="grid lg:grid-cols-3 gap-3">
                {products?.map((item) => (
                  <div className="border rounded-[8px] shadow-md p-2 relative">
                    <FaTrash
                      onClick={() => handleDelete(item._id)}
                      className="text-red-500 absolute right-2 top-2 cursor-pointer"
                    />
                    <span>{item.content}</span>
                    <span className="p-2 flex justify-between flex-wrap gap-3">
                      <span>coin:</span>
                      <span dir="ltr">{item.point}</span>
                    </span>
                  </div>
                ))}
              </div>
            )}
            <div className="mt-3">
              <button onClick={() => setcreate(true)} className="btn-brand">
                Create New Task
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TaskPage;
