import { useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { httpReauest } from "../../../utils/httpRequest";
import * as Yup from "yup";
import { BASE_URL } from "../../../config";
import Loading from "../Loading";
import { FaTrash } from "react-icons/fa6";
import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from "react-quill";

const CreateSubProductForm = () => {
  const [img, setImg] = useState();
  const [images, setImages] = useState([]);
  const [previews, setPreview] = useState([]);
  const [previewImg, setPreviewImg] = useState();
  const [categorys, setCategorys] = useState();
  const [loading, setloading] = useState();
  const [previewCatalog, setpreviewCatalog] = useState();
  const [catalog, setCatalog] = useState();
  const [previewVideo, setPreviewVideo] = useState();
  const [video, setVideo] = useState();

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      text: Yup.string().required("required"),
    });
  };

  const formik = useFormik({
    initialValues: {
      text: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const formdata = new FormData();
      formdata.append("text", values.text);
      if (img) {
        formdata.append("img", img, img?.name);
      }

      await fetch(BASE_URL + "/user/send", {
        method: "POST",
        body: formdata,
        headers: { "x-access-token": user.token },
      }).then((dat) => {
        if (dat?.status == 201) {
          toast.success("Succesfull");
          setloading(false);
          formik.resetForm();
          setPreviewImg(null);
          setImg(null);
        }
      });
    },
  });

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("file size should undes 2 mb");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">Send Global Message</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative col-span-2">
          <textarea
            id="text"
            name="text"
            value={formik.values.text}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[200px]"
          />
          <p className="on-border font-bold">text*</p>
        </span>
        {formik.errors.text && formik.touched.text && (
          <small style={{ color: "red", fontSize: "14px" }}>
            {formik.errors.text}
          </small>
        )}
        <label
          htmlFor="img"
          className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444]"
        >
          {previewImg ? (
            <img
              src={previewImg}
              className="w-full h-full object-contain"
              alt=""
            />
          ) : (
            <FaPlus className="center" size={30} />
          )}

          <p className="on-border font-bold">image</p>
        </label>
        <input
          onChange={(e) => {
            handleImg(e);
          }}
          type="file"
          className="hidden"
          id="img"
          accept="image/png, image/jpg, image/jpeg"
        />
        <span className="col-span-full">
          {loading ? (
            <Loading />
          ) : (
            <button type="submit" className="btn-brand">
         Submit
            </button>
          )}
        </span>
      </form>
    </div>
  );
};

export default CreateSubProductForm;
